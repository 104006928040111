import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Text, Box, Link, Flex } from 'rebass';
import Fade from 'react-reveal/Fade';
import SocialLink from './SocialLink';

const FooterContainer = styled.div`
  min-width: 320px;
  max-width: 1366px;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
`;

const TextFooter = styled(Text)`
  color: ${props => props.theme.colors.background};

  & a {
    color: ${props => props.theme.colors.background};
  }
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allFile(filter: { relativeDirectory: { eq: "socialLinks" } }) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                fontAwesomeIcon
                name
                title
                url
              }
            }
          }
        }
      }
      file(relativePath: { eq: "me/about-me.md" }) {
        childMarkdownRemark {
          frontmatter {
            myTitle
          }
        }
      }
    }
  `);

  const name = data.file.childMarkdownRemark.frontmatter.myTitle;
  const socialLinks = data.allFile.edges.map(
    x => x.node.childMarkdownRemark.frontmatter,
  );

  return (
    <Box p={3} backgroundColor="primaryDark" as="footer">
      <FooterContainer>
        <Fade left>
          <TextFooter fontSize={[2, 3]}>
            <span>{`${name} Portfolio - Powered by `}</span>
            <Link href="https://www.gatsbyjs.org/">Gatsby</Link>
            <span>, </span>
            <Link href="https://gatsby-starter-mate.netlify.app/" mr={1}>
              Gatsby Starter Mate
            </Link>
            <span> and </span>
            <Link href="https://www.netlify.com/" mr={1}>
              Netlify
            </Link>
            <span role="img" aria-label="heart">
              ❤️
            </span>
          </TextFooter>
        </Fade>
        <Flex>
          <Fade right>
            {socialLinks.map(({ id, ...rest }) => (
              <Box mx={[2, 3]} fontSize={[4, 5]} key={id}>
                <SocialLink {...rest} color="background" />
              </Box>
            ))}
          </Fade>
        </Flex>
      </FooterContainer>
    </Box>
  );
};

export default Footer;
