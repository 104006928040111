import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Heading, Flex, Box, Text } from 'rebass';
import TextLoop from 'react-text-loop';
import { SectionLink } from 'react-scroll-section';
import Section from '../components/Section';
import SocialLink from '../components/SocialLink';
import MouseIcon from '../components/MouseIcon';
import Triangle from '../components/Triangle';

const Background = () => (
  <div>
    <Triangle
      color="backgroundDark"
      height={['35vh', '80vh']}
      width={['95vw', '60vw']}
    />

    <Triangle
      color="secondary"
      height={['38vh', '80vh']}
      width={['50vw', '35vw']}
    />

    <Triangle
      color="primaryDark"
      height={['25vh', '35vh']}
      width={['75vw', '60vw']}
      invertX
    />

    <Triangle
      color="backgroundDark"
      height={['20vh', '20vh']}
      width={['100vw', '100vw']}
      invertX
      invertY
    />
  </div>
);

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };

const LandingPage = () => {
  const data = useStaticQuery(graphql`
    query LandingQuery {
      allFile(filter: { relativeDirectory: { eq: "socialLinks" } }) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                fontAwesomeIcon
                name
                title
                url
              }
            }
          }
        }
      }
      file(relativePath: { eq: "me/about-me.md" }) {
        childMarkdownRemark {
          frontmatter {
            roles {
              role
            }
            myName
          }
        }
      }
    }
  `);

  const socialLinks = data.allFile.edges.map(
    x => x.node.childMarkdownRemark.frontmatter,
  );

  const name = data.file.childMarkdownRemark.frontmatter.myName;
  const roles = data.file.childMarkdownRemark.frontmatter.roles.map(
    x => x.role,
  );

  return (
    <Section.Container id="home" Background={Background}>
      <Fragment>
        <Heading
          textAlign="center"
          as="h1"
          color="primary"
          fontSize={[5, 6, 8]}
          mb={[3, 4, 5]}
        >
          {`Hello, I'm ${name}!`}
        </Heading>

        <Heading
          as="h2"
          color="primary"
          fontSize={[4, 5, 6]}
          mb={[3, 5]}
          textAlign="center"
          style={centerHorizontally}
        >
          <TextLoop interval={5000}>
            {roles
              .sort(() => Math.random() - 0.5)
              .map(text => (
                <Text width={[300, 500]} key={text}>
                  {text}
                </Text>
              ))}
          </TextLoop>
        </Heading>

        <Flex alignItems="center" justifyContent="center" flexWrap="wrap">
          {socialLinks.map(({ id, ...rest }) => (
            <Box mx={3} fontSize={[5, 6, 6]} key={id}>
              <SocialLink {...rest} />
            </Box>
          ))}
        </Flex>
        <SectionLink section="about">
          {({ onClick }) => <MouseIcon onClick={onClick} />}
        </SectionLink>
      </Fragment>
    </Section.Container>
  );
};

export default LandingPage;
